<template>
  <b-card>
    <b-list-group>
      <b-list-group-item v-if="itemData.status === '1'">
        <div class="font-weight-bold text-primary">
          Onay Kodu
        </div>
        <div>{{ itemData.code }}</div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          SMS ID
        </div>
        <div>{{ itemData.sms_id }}</div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Durum
        </div>
        <div>{{ itemData.status === '1'? 'Onaylandı' : 'Onaylanmadı' }}</div>
        <div
          v-if="itemData.status === '1'"
          class="text-warning"
        >
          {{ moment(itemData.approved).format('LLLL') }}
        </div>
        <div class="text-muted font-small-2">
          {{ itemData.username }}
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Müşteri
        </div>
        <div>{{ itemData.customer }}</div>
        <div
          v-if="itemData.customer"
          class="font-small-2 text-muted"
        >
          {{ itemData.company }}
        </div>
        <div>{{ itemData.phone }}</div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          SMS İçeriği
        </div>
        <div>{{ itemData.text }}</div>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>
<script>
import { BCard, BListGroup, BListGroupItem } from 'bootstrap-vue'

export default {
  name: 'Add',
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
  },
  computed: {
    itemData() {
      return this.$store.getters['serviceSms/itemData']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('serviceSms/smsItem', this.$route.params.id)
    },
  },
}
</script>
